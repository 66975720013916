var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.debugMode)?_c('div',{staticStyle:{"font-size":"12px","margin-bottom":"20px"}},[_c('span',[_vm._v(_vm._s(JSON.stringify(_vm.item)))])]):_vm._e(),(_vm.item.data.elementName === 'el-row')?_c('el-row',{attrs:{"gutter":20}},_vm._l((_vm.item.children),function(col,index){return _c('el-col',{key:index,attrs:{"span":24 / _vm.item.children.length}},[(col.formControl)?[(_vm.debugMode)?_c('span',{staticStyle:{"font-size":"12px","color":"#1c6ca1"}},[_vm._v(_vm._s(col.formControl)+" / "+_vm._s(_vm.getValue(col.formControl)))]):_vm._e(),(
            col.formControl &&
            ['el-input', 'input', 'number'].includes(col.formControl.elementType)
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('el-input',{attrs:{"disabled":"","value":_vm.getValue(col.formControl)}})],1):_vm._e(),(
            col.formControl &&
            ['el-textarea', 'textarea'].includes(col.formControl.elementType)
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('el-input',{attrs:{"type":"textarea","disabled":"","rows":5,"value":_vm.getValue(col.formControl)}})],1):_vm._e(),(
            col.formControl &&
            ['dropdownList', 'multipleDropdownList'].includes(col.formControl.elementType)
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('select-view',{attrs:{"value":_vm.getValue(col.formControl),"form-control":col.formControl}})],1):_vm._e(),(
            col.formControl &&
            ['Ueditor', 'editor', 'u-editor'].includes(col.formControl.elementType)
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('editor-view',{attrs:{"value":_vm.getValue(col.formControl),"form-control":col.formControl}})],1):_vm._e(),(
            col.formControl &&
            ['date', 'datetime', 'date-picker', 'dateTime'].includes(
              col.formControl.elementType
            )
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"disabled":"","value":_vm.getValue(col.formControl)}})],1):_vm._e(),(
            col.formControl &&
            ['el-switch', 'switch'].includes(col.formControl.elementType)
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('el-switch',{attrs:{"value":_vm.getValue(col.formControl)}})],1):_vm._e(),(
            col.formControl &&
            ['radio', 'el-radio', 'Radio'].includes(
              col.formControl.elementType
            )
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('radio-view',{attrs:{"value":_vm.getValue(col.formControl),"form-control":col.formControl}})],1):_vm._e(),(
            col.formControl &&
            [
              'province-city-area-town-control',
              'ProvinceCityAreaTownControl',
              'provinceCityAreaTownControl' ].includes(col.formControl.elementType)
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('province-city-area-view',{attrs:{"host-data":_vm.getValue(col.formControl)}})],1):_vm._e(),(
            col.formControl &&
            ['upload-simple-image'].includes(col.formControl.elementType)
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('el-avatar',{attrs:{"shape":"square","size":200,"fit":"cover","src":_vm.getValue(col.formControl)},on:{"error":function () { return true; }}},[_c('img',{attrs:{"src":"https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"}})])],1):_vm._e(),(
            col.formControl &&
            ['attachments'].includes(
              col.formControl.elementType
            )
          )?_c('el-form-item',{attrs:{"label":col.formControl.fieldDisplayName,"required":col.formControl.required}},[_c('attachments-view',{attrs:{"hostId":_vm.hostId,"hostType":_vm.uploadHostType}})],1):_vm._e()]:_vm._e()],2)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }