var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('paged-table-view',{attrs:{"search-function":_vm.fetchData,"query-form":_vm.queryForm,"use-cache":!_vm.workOrderId},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[(!_vm.workOrderId)?_c('el-input',{attrs:{"clearable":"","placeholder":"请输入工单名称"},model:{value:(_vm.queryForm.displayName),callback:function ($$v) {_vm.$set(_vm.queryForm, "displayName", $$v)},expression:"queryForm.displayName"}}):_vm._e()],1),_c('el-form-item',[_c('el-select',{attrs:{"clearable":"","placeholder":"状态"},model:{value:(_vm.queryForm.status),callback:function ($$v) {_vm.$set(_vm.queryForm, "status", $$v)},expression:"queryForm.status"}},[_c('el-option',{attrs:{"value":"ToBeReviewed","label":"待验收"}}),_c('el-option',{attrs:{"value":"Pass","label":"验收通过"}}),_c('el-option',{attrs:{"value":"Reject","label":"已退回"}})],1)],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('el-divider',{staticClass:"query-form-divider"}),_c('export-button',{attrs:{"url":"/api/services/app/WorkOrderCompletion/Export","file-title":"工单验收列表","query-model":_vm.queryForm,"buttonTitle":"导出"}})]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":"认领人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.creatorUser.realName)+" ")]}}])}),(!_vm.selfWorkOrderId)?_c('el-table-column',{attrs:{"label":"工单名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workOrder.displayName)+" ")]}}],null,false,142630883)}):_vm._e(),(!_vm.selfWorkOrderId)?_c('el-table-column',{attrs:{"label":"工单编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workOrder.workOrderNo)+" ")]}}],null,false,3461229584)}):_vm._e(),_c('el-table-column',{attrs:{"label":"认领时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.workOrderParticipator.claimTime))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"工单单价"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workOrder.orderUnitPrice)+"/"+_vm._s(row.workOrder.orderUnitName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"累计工作量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.totalOutputCount)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"总金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.totalAmount)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('work-order-completion-status-tag',{attrs:{"dto":row}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleViewDetail(row)}}},[_vm._v("查看 ")]),(row.status === 'ToBeReviewed')?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handlePass(row)}}},[_vm._v("验收通过 ")]):_vm._e(),(row.status === 'ToBeReviewed')?_c('el-popover',{attrs:{"width":"400","placement":"left"},model:{value:(_vm.showRejectPopover),callback:function ($$v) {_vm.showRejectPopover=$$v},expression:"showRejectPopover"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.rejectMessage),callback:function ($$v) {_vm.rejectMessage=$$v},expression:"rejectMessage"}}),_c('div',{staticStyle:{"text-align":"right","margin-top":"10px"}},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){_vm.showRejectPopover = false}}},[_vm._v("取消 ")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleReject(row)}}},[_vm._v("确定 ")])],1),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"slot":"reference","size":"mini","type":"danger"},slot:"reference"},[_vm._v(" 退回 ")])],1):_vm._e()]}}])})]},proxy:true}])}),_c('el-drawer',{attrs:{"visible":_vm.showDetail,"title":"验收信息详情","direction":"rtl"},on:{"update:visible":function($event){_vm.showDetail=$event}}},[(_vm.showDetail)?_c('work-order-check-and-accept-detail',{attrs:{"work-order-completion":_vm.detailItem}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }