var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.WorkOrder.Orders.Assign'),expression:"'Pages.WorkOrder.Orders.Assign'"}],staticStyle:{"margin-bottom":"20px"},attrs:{"type":"primary"},on:{"click":_vm.handleAssign}},[_vm._v("指派 ")]),_c('work-order-assign-dialog',{ref:"workOrderAssignDialog",attrs:{"work-order-id":_vm.workOrderId},on:{"assigned":_vm.refreshData}}),_c('paged-table-view-simple',{ref:"pagedTable",attrs:{"search-function":_vm.fetchData},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('el-table-column',{attrs:{"label":"姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.realName)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"isAssign","label":"方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isAssign)?_c('el-tag',{attrs:{"type":"primary"}},[_vm._v("指派")]):_c('el-tag',{attrs:{"type":"default"}},[_vm._v("抢单")])]}}])}),_c('el-table-column',{attrs:{"label":"抢单信息","prop":"claimTime","width":"260"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.isAssign)?_c('div',[_vm._v(" 抢单时间："+_vm._s(_vm._f("formatDate")(row.claimTime))+" "),(row.isPass)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已通过")]):_vm._e(),(row.isPass === false)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(" 已拒绝 "),_c('el-tooltip',{attrs:{"content":("退回原因：" + (row.rejectReason))}},[_c('i',{staticClass:"el-icon-question"})])],1):_vm._e(),(row.isPass === undefined || row.isPass === null)?_c('el-tag',{attrs:{"type":"default"}},[_vm._v(" 待审核 ")]):_vm._e(),(row.competeAuditTime)?_c('span',[_c('br'),_vm._v("审核时间： "+_vm._s(_vm._f("formatDate")(row.competeAuditTime,"YYYY-MM-DD HH:mm"))+" ")]):_vm._e()],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"指派信息","width":"260"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isAssign)?_c('div',[_vm._v(" 指派时间："+_vm._s(_vm._f("formatDate")(row.creationTime))+" "),(row.claimTime)?_c('div',[_c('br'),_vm._v("认领时间："+_vm._s(_vm._f("formatDate")(row.claimTime))+" ")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"状态","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('work-order-participator-status-tag',{attrs:{"status":row.status}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.isAssign && row.isPass === null)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleCompetePass(row)}}},[_vm._v("通过 ")]):_vm._e(),_c('el-popover',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_c('el-input',{attrs:{"type":"textarea","placeholder":"请填写拒绝原因"},model:{value:(_vm.rejectMessage),callback:function ($$v) {_vm.rejectMessage=$$v},expression:"rejectMessage"}}),_c('div',{staticStyle:{"text-align":"right","margin":"0","padding-top":"10px"}},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.hideRejectMessage(row.id)}}},[_vm._v("取消 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.WorkOrder.Orders.CompeteAudit'),expression:"'Pages.WorkOrder.Orders.CompeteAudit'"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleCompeteReject(row)}}},[_vm._v("确定 ")])],1)],1)]},proxy:true},{key:"reference",fn:function(){return [(!row.isAssign && row.isPass === null)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.WorkOrder.Orders.CompeteAudit'),expression:"'Pages.WorkOrder.Orders.CompeteAudit'"}],staticStyle:{"margin-left":"10px"},attrs:{"size":"mini","type":"danger"}},[_vm._v("拒绝 ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.showRejectMessage[row.id]),callback:function ($$v) {_vm.$set(_vm.showRejectMessage, row.id, $$v)},expression:"showRejectMessage[row.id]"}})]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }