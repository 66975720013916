<template>
  <ueditor :readonly="true" ref="editor" v-model="val"></ueditor>
</template>

<script>
import { AbstractDataSourceView } from "./AbstractDataSourceView";
import { Component } from "vue-property-decorator";
import Ueditor from "@/components/Ueditor.vue";

@Component({
  name: "EditorView",
  components:{
    Ueditor
  }
})
export default class EditorView extends AbstractDataSourceView {
  val = "";

  created() {
    this.val = this.value;
  }

}
</script>

<style scoped></style>
