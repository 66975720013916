<template>
  <el-radio-group :value="value">
    <el-radio
      v-for="radioItem in dataSource"
      :key="radioItem.value"
      disabled
      :label="radioItem.value.toString()"
      >{{ radioItem.text }}
    </el-radio>
  </el-radio-group>
</template>

<script>
import { AbstractDataSourceView } from "./AbstractDataSourceView";
import { Component } from "vue-property-decorator";

@Component({
  name: "RadioView",
})
export default class RadioView extends AbstractDataSourceView {
  created() {
    this.initDataSource();
  }
}
</script>

<style scoped></style>
