<template>
  <el-select
    disabled
    v-model="val"
    :multiple="isMultipleSelect"
    class="width-100"
  >
    <el-option
      v-for="(selectItem, i) in dataSource"
      :key="i"
      :label="selectItem.text"
      :value="selectItem.value"
    ></el-option>
  </el-select>
</template>

<script>
import { AbstractDataSourceView } from "./AbstractDataSourceView";
import { Component } from "vue-property-decorator";

@Component({
  name: "SelectView"
})
export default class SelectView extends AbstractDataSourceView {

  get isMultipleSelect() {
    return ["multipleDropdownList"].some((name) => name === this.formControl.elementType);
  }

  val = "";

  created() {
    if (this.isMultipleSelect) {
      if (this.value && this.value.length > 0) {
        this.val = this.value.split(",");
      } else {
        this.val = "";
      }
    } else {
      this.val = this.value + "";
    }
    this.initDataSource();
  }
}
</script>

<style scoped></style>
